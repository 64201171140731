<template>
  <div class="cart-container">
    <div class="cart-title">购物车</div>
    <p class="authorized-download-tips"><el-icon name="warning" /><span>电脑浏览器默认自动拦截下载文件，需提前进行设置允许浏览器下载文件，详情参考【<a
          href="/help" target="_blank" style="color: #E52A0D">帮助中心</a>】。</span></p>
    <div class="cart-main">
      <el-table ref="multipleTable" :data="cartList || []" class="cart-table" cell-class-name="cart-table-cell-class"
        @row-click="rowClick">
        <!-- @selection-change="handleSelectChange" -->
        <!-- <el-table-column type="selection" width="90px" align="center" /> -->
        <el-table-column width="30px" align="center" />
        <el-table-column label="名称" prop="name">
          <template slot-scope="{ row: { name, logo, type, video_url } }">
            <div class="music-info" v-if="type !== 4">
              <el-image class="music-img" :src="logo">
                <div slot="error" class="image-slot">
                  <img src="@/assets/image/default-music-img.png" alt="" />
                </div>
              </el-image>
              <span class="music-name">{{ name }}</span>
            </div>
            <div class="music-info" v-else>
              <!-- <div class="video_poster"> -->
              <el-image class="music-img" :src="logo" v-if="logo !== ''" style="border-radius:2px">
              </el-image>
              <video ref="video" muted class="video" v-else>
                <source :src="video_url" />
              </video>
              <!-- </div> -->
              <span class="music-name">{{ name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="type" align="center">
          <template slot-scope="{ row: { type } }">
            {{ type | transitionValToLabel(goodsType) }}
          </template>
        </el-table-column>
        <el-table-column label="授权信息" align="center">
          <template>随片永久</template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="op-tem11111">
          <!-- { row: { cart_id, type } } -->
          <template slot-scope="scope">
            <el-button type="text" @click.stop="handleDelete(scope.row.cart_id, scope.row.type)">删除</el-button>
            <el-button class="download_btn" size="small" @click.stop="handleDownload(scope.row)">立即下载</el-button>
          </template>
        </el-table-column>

        <div slot="empty">
          <el-empty description="空空如也~" />
        </div>
      </el-table>
      <!-- <el-button
        class="batch-download-btn"
        :disabled="batchDownload"
        type="primary"
        @click="handleDownload(multipleSelection, 'batch')">立即下载</el-button> -->
    </div>
  </div>
</template>

<script>
import { delCardGoods } from '@/api/cart'
import { GOODS_TYPE } from '@/enum/common'
import { getCartList } from '@/utils/operate'
import { mapGetters, mapState } from 'vuex'
import Icon from '@/assets/svg/question.svg'
import Buttons from '@/layout/mixins/Buttons'
import { transitionValToLabel } from '@/filters'
// const Icon = require('@/assets/svg/question.svg')

export default {
  name: 'Cart',
  data() {
    return {
      goodsType: [...Object.values(GOODS_TYPE)],
      multipleSelection: []
    }
  },
  computed: {
    ...mapGetters(['cartList']),
    ...mapState({
      cartDownloadList: (state) => state.download.cartDownloadList
    }),
    batchDownload() {
      return !(this.multipleSelection.length > 0)
    }
  },
  watch: {
    cartDownloadList: {
      async handler(list) {
        await delCardGoods({
          cart_id: list[0].cart_id
        })
        await getCartList()
      },
      deep: true
    }
  },
  mixins: [Buttons],
  created() {
    getCartList()
  },
  methods: {
    /**
     * 选中购物车内容
     * 2021-12-22 16:00:34
     * @author SnowRock
     * @param rows
     */
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      }
    },
    /**
     * 处理选择内容
     * 2021-12-22 14:30:45
     * @author SnowRock
     */
    handleSelectChange(val) {
      this.multipleSelection = val
    },
    /**
     * 删除功能
     * 2021-12-22 14:33:57
     * @author SnowRock
     */
    handleDelete(cart_id, type) {
      this.$confirm(
        '<p class="question-container"><img class="question-icon" src="' +
        Icon +
        '" alt=""><span>确定要删除这首' +
        transitionValToLabel(type, this.goodsType) +
        '吗？</span></p>',
        '',
        {
          customClass: 'question-message-box',
          dangerouslyUseHTMLString: true,
          type: 'question'
        }
      ).then(async (action) => {
        if (action === 'confirm') {
          try {
            await delCardGoods({
              cart_id
            })
            this.$message.success('删除成功')
            getCartList()
          } catch (e) {
            console.log(e)
          }
        }
      })
    },
    rowClick(row) {
      if (row.type === 4) {
        // 视频
        this.$router.push(`/ video / detail ? videoId = ${row.object_id} `);
      } else {
        // 音乐、音效、ai
        this.$store.dispatch('music/pause');
        this.handlePlayerMusic({
          ...row,
          music_id: row.object_id,
          watermark_file: row.file_url
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$max-width: 1200px;

.center {
  width: $max-width;
  margin: 0 auto;
}

.cart-container {
  padding-top: 70px;
  padding-bottom: 80px;
  min-height: calc(100vh - 268px);

  .authorized-download-tips {
    @extend .center;
    margin-bottom: 16px;
    color: rgba(51, 51, 51, 0.8);
    font-size: 14px;
    line-height: 16px;

    .el-icon-warning {
      margin-right: 5px;
      font-size: 18px;
      color: #ff8e14;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }

  .cart-title {
    @extend .center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.6);
    line-height: 28px;
    padding-top: 40px;
    padding-bottom: 24px;
  }

  .cart-main {
    @extend .center;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(33, 48, 83, 0.07);
    border-radius: 12px;
    text-align: right;
    padding-bottom: 52px;
    color: rgba(51, 51, 51, 0.8);

    .batch-download-btn {
      margin-top: 36px;
      margin-right: 75px;
      padding: 4px 9px;
    }

    .music-info {
      display: flex;
      align-items: center;

      .video {
        width: 72px;
        height: 72px;
        border-radius: 2px;
        background: transparent no-repeat;
        background-size: cover;
        margin-right: 36px;
      }

      .music-img {
        width: 72px;
        height: 72px;
        border-radius: 2px;
        vertical-align: middle;
        margin-right: 36px;

        img {
          width: 100%;
        }
      }

      span {
        display: inline-block;
        vertical-align: middle;
        max-width: 151px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    /deep/ {
      .cart-table {
        .el-table__header {
          .el-table__cell {
            color: rgba(51, 51, 51, 0.8);

            .el-checkbox {
              &:after {
                content: '全选';
                margin-left: 10px;
                margin-right: calc(-2em - 10px);
              }
            }
          }
        }

        .cart-table-cell-class {}
      }
    }
  }
}

.download_btn {
  border: 2px solid #E52A0D;
  border-radius: 4px;
  width: 86px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #E52A0D;
  padding: 6px 0px;
}
</style>
